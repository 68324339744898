import React from 'react';
import logo from '../../assets/svg/primary-logo.svg';
import {NavLink} from "react-router-dom";

function Navbar() {
    const activeClassName: string = 'active';

    return (
        <nav className="navbar navbar-expand-lg navbar-light primary-navbar">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className='navbar-logo ms-auto p-0 d-flex d-lg-none'>
                <div className='navbar-logo-container'>
                    <img src={logo} alt=""/>
                </div>
            </div>
           <div className='collapse navbar-collapse' id="navbarSupportedContent">
               <div className="navbar-items d-lg-flex col-3" >
                   <NavLink
                       to='/'
                       className={({isActive}) =>
                           isActive ? `${activeClassName}` : ''
                       }
                   >Inicio</NavLink>
                   <NavLink
                       to='/buscador'
                       className={({isActive}) =>
                           isActive ? `${activeClassName}` : ''
                       }
                   >Buscador</NavLink>

                   <NavLink
                       to='/nosotras'
                       className={({isActive}) =>
                           isActive ? `${activeClassName}` : ''
                       }
                   >Nosotras</NavLink>

                   <NavLink
                       to='/blog'
                       className={({isActive}) =>
                           isActive ? `${activeClassName}` : ''
                       }
                   >Blog</NavLink>
               </div>

               <div className='navbar-logo col-6 d-none d-lg-flex'>
                   <div className='navbar-logo-container'>
                       <img src={logo} alt=""/>
                   </div>
               </div>

               <div className='navbar-end flex-fill d-none d-lg-flex'>
                   <div>
                       <button className='btn-rounded btn-calipso'>Suscríbete</button>
                   </div>
               </div>

           </div>
        </nav>
    );
}

export default Navbar;

/*
 <nav className='primary-navbar navbar navbar-expand-lg navbar-light'>

            <div className='navbar-items col-3 '>
                <NavLink
                    to='/'
                    className={({isActive}) =>
                        isActive ? `${activeClassName}` : ''
                    }
                >Inicio</NavLink>
                <NavLink
                    to='/buscador'
                    className={({isActive}) =>
                        isActive ? `${activeClassName}` : ''
                    }
                >Buscador</NavLink>

                <NavLink
                    to='/nosotras'
                    className={({isActive}) =>
                        isActive ? `${activeClassName}` : ''
                    }
                >Nosotras</NavLink>

                <NavLink
                    to='/blog'
                    className={({isActive}) =>
                        isActive ? `${activeClassName}` : ''
                    }
                >Blog</NavLink>

            </div>

            <div className='navbar-logo col-6'>
                <div className='navbar-logo-container'>
                    <img src={logo} alt=""/>
                </div>
            </div>

            <div className='navbar-end col-3'>
                <div>
                    <button className='btn-rounded btn-calipso'>Suscríbete</button>
                </div>
            </div>


        </nav>
 */
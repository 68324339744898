import axios from "axios";

const url = 'https://finder-ysccph2aja-tl.a.run.app/allegory/api/v1.0';

export const getProducts = (values: Array<string>) => {

    const params = new URLSearchParams();
    params.append('prenda', values[0]);
    params.append('estilo', values[1]);
    params.append('colorimetria', values[2]);
    params.append('cuerpo', values[3]);
    params.append('ocasion', values[4]);
    params.append('etapa', values[5]);


    return axios.get(url, {params});

}
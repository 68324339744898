import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../redux";
import {getProducts} from "../api/api";
import {useNavigate} from "react-router-dom";

function Results() {

    const searchState = useSelector((state: RootState) => state.search);
    const navigate = useNavigate()

    const [products, setProducts] = useState<Array<any>>();

    useEffect(() => {
        getProducts(searchState.categoriesValue)
            .then(res => res.data)
            .then(data => {
                if (typeof data !== "object") throw Error
                setProducts(data);
            })
            .catch(() => {
                navigate('/');
            })
    }, [])

    return (
        <main className='bg-white'>
            <div className='d-flex flex-column justify-content-center'>

                <div className='container d-flex justify-content-between mt-4'>
                    <p className='mx-auto'>Buscaste: &nbsp;
                        {searchState.categoriesValue.join(' - ')}

                    </p>
                    <p className='mx-auto'>Productos encontrados: {products?.length}</p>
                    <p className='mx-auto'>Ordenar productos por </p>
                </div>

                <div className='container d-flex flex-wrap justify-content-center'>

                    {products?.map((product, index) => {
                        const priceFormat = Intl.NumberFormat('es',{style:'currency',currency:'CLP'});
                        return (
                            <div key={index} className='product-card-result'>
                                <div className='img-container' style={{marginBottom: '20px'}}>
                                    <img src={product.image} alt=""/>
                                </div>
                                <div>
                                    <h5 className='w700'>{product.title}</h5>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p>{product.commerce}</p>
                                    <p>{priceFormat.format(product.price)}</p>
                                </div>
                                <p className='w700'>Match {product.pof}%</p>
                                <a href={product.link} target={'_blank'} className='btn-rounded btn-dark-c py-2'>Ver
                                    Producto</a>
                            </div>
                        );
                    })}
                </div>


            </div>
        </main>
    );
}

export default Results;
import { configureStore } from '@reduxjs/toolkit';
import SearchSlice from "./slices/SearchSlice";
const { NODE_ENV } = process.env;



export const store = configureStore({
    reducer: {
        search:SearchSlice,
    },
    devTools: NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
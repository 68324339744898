import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux";
import {setSearchState} from "../redux/slices/SearchSlice";
import ProductsForm from "../components/forms/ProductsForm";
import StyleForm from "../components/forms/StyleForm";
import ColorimetriaForm from "../components/forms/ColorimetriaForm";
import BodyShapeForm from "../components/forms/BodyShapeForm";
import SituationForm from "../components/forms/SituationForm";
import StageForm from "../components/forms/StageForm";
import {useNavigate} from "react-router-dom";

function Buscador() {

    const dispatch = useDispatch();
    const searchState = useSelector((state: RootState) => state.search);
    const navigate = useNavigate()


    return (
        <main className='bg-white'>
            <nav className='tab-selector'>
                {
                    searchState.categories.map((category, key) => {
                        return (
                            <div onClick={() => {
                                if (searchState.categoriesValue[key] !== null) {
                                    dispatch(setSearchState({currentIndex: key - 1}))
                                }
                            }
                            }
                                 key={key}
                                 className={`item 
                                    ${category === searchState.categories[searchState.currentIndex] ? 'active' : ''}
                                    ${searchState.categoriesValue[key] !== null ? 'enabled' : ''}`}
                            >
                                <p>{category}</p>
                            </div>
                        )
                    })
                }
            </nav>

            {renderContent()}

            <div className='d-flex justify-content-center my-3'>
                <div
                    className={`btn-rounded btn-dark-c ${searchState.categoriesValue[searchState.currentIndex] === null ? 'disabled disabled-bg' : ''}`}
                    onClick={() => {
                        if (searchState.currentIndex === searchState.categories.length -1 ) {
                            console.log('AAAAAAAAAAAAAAAAAAAA');
                            navigate('/resultados');
                        }
                        else if (searchState.categoriesValue[searchState.currentIndex] !== null) {
                            dispatch(
                                setSearchState({
                                    currentIndex: searchState.currentIndex,
                                })
                            )
                        }
                    }}
                >
                    <div className='d-flex align-items-center'>
                        <p>{searchState.currentIndex === searchState.categories.length-1? 'Buscar':'Siguiente'}</p>
                    </div>
                </div>
            </div>

        </main>
    );

    function renderContent() {
        switch (searchState.currentIndex) {
            case 0:
                return <ProductsForm/>;
            case 1:
                return <StyleForm/>;
            case 2:
                return <ColorimetriaForm/>;
            case 3:
                return <BodyShapeForm/>;
            case 4:
                return <SituationForm/>;
            case 5:
                return <StageForm/>;
            default:
                return <ProductsForm/>;
        }

    }

}


export default Buscador;
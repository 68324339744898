import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../redux/slices/SearchSlice";
import {RootState} from "../../redux";
import situations from '../../assets/situation.json'
import {normalizeString} from "../../utils";

function SituationForm() {

    const dispatch = useDispatch();
    const searchState = useSelector((state: RootState) => state.search);

    return (
        <div className='search-container '>
            <div className='mt-5 mb-5'>
                <h2 className='mb-3'>Ocasión</h2>
                <p>La búsqueda de una prenda depende también para que ocasión estamos buscando. Selecciona la
                    alternativa que más se acerque a lo que buscas:</p>
            </div>

            <div className='d-flex flex-wrap justify-content-center '>
                {situations.map((situation, key) => {
                    return (
                        <div
                            key={key}
                            className={`btn-square-calipso text-center ${normalizeString(situation.label) === searchState.categoriesValue[searchState.currentIndex] ? 'active' : ''} `}
                            onClick={() => dispatch(setSelected({value: situation.label, currentIndex: 4}))}
                        >
                            <span>{situation.label}</span>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}

export default SituationForm;
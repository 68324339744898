import React from 'react';
import post1 from '../assets/img/post-1.png'
import post2 from '../assets/img/post-2.png'
import post3 from '../assets/img/post-3.png'

function Blog() {
    return (
        <main>

            <div className='text-center my-4'>
                <h1 className='font-zs'>Blog</h1>
            </div>


            <div className='d-flex flex-wrap justify-content-center'>

                <div className='post-card'>
                    <div className='img-container'>
                        <img src={post1} alt=""/>
                    </div>
                    <div className='d-flex flex-column post-body'>
                        <h1 className='my-1'>Colorimetría</h1>
                        <p className='my-1'>¿Qué es la Colorimetría? Es el estudio basado en la Teoría del color según
                            las
                            características físicas de las personas...</p>
                    </div>
                    <div className='d-flex '>
                        <p className='ms-auto w700'>Leer más</p>
                    </div>
                </div>

                <div className='post-card'>
                    <div className='img-container'>
                        <img src={post2} alt=""/>
                    </div>
                    <div className='d-flex flex-column post-body'>
                        <h1 className='my-1'>Estilo</h1>
                        <p className='my-1'>Existen muchos estilos o maneras de vestirse que dependen de la edad,
                            cultura, actividad laboral...</p>
                    </div>
                    <div className='d-flex '>
                        <p className='ms-auto w700'>Leer más</p>
                    </div>
                </div>

                <div className='post-card'>
                    <div className='img-container'>
                        <img src={post3} alt=""/>
                    </div>
                    <div className='d-flex flex-column post-body'>
                        <h1 className='my-1'>Sobre Allegory</h1>
                        <p className='my-1'>No importa tu tipo de cuerpo, estatura, color de ojos, piel o pelo para
                            encontrar tu estilo, sólo debes buscar lo que te...
                        </p>
                    </div>
                    <div className='d-flex '>
                        <p className='ms-auto w700'>Leer más</p>
                    </div>

                </div>

            </div>

        </main>
    );
}

export default Blog;
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../redux/slices/SearchSlice";
import {RootState} from "../../redux";
import style from '../../assets/styles.json'
import {normalizeString} from "../../utils";

function ProductsForm() {

    const dispatch = useDispatch();
    const searchState = useSelector((state: RootState) => state.search);

    return (
        <div className='search-container '>
            <div className='mt-5 mb-5'>
                <h2 className='mb-3'>Estilo</h2>
                <p>Existen muchos estilos o maneras de vestirse que dependen de la edad, cultura, actividad laboral o
                    simplemente de nuestros gustos personales. Escoge el tuyo:</p>
            </div>

            <div className='d-flex flex-wrap justify-content-center '>
                {style.map((style, key) => {
                    return (
                        <div
                            key={key}
                            className={`btn-square-calipso ${normalizeString(style.label) === searchState.categoriesValue[searchState.currentIndex] ? 'active' : ''} `}
                            onClick={() => dispatch(setSelected({value: style.label, currentIndex: 1}))}
                        >

                            <span>{style.label}</span>
                        </div>
                    )
                })}
            </div>


        </div>
    );
}

export default ProductsForm;
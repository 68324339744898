import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../redux/slices/SearchSlice";
import {RootState} from "../../redux";
import colorimetrias from '../../assets/colorimetria.json'
import {normalizeString} from "../../utils";

function ProductsForm() {

    const dispatch = useDispatch();
    const searchState = useSelector((state: RootState) => state.search);

    return (
        <div className='search-container '>
            <div className='mt-5 mb-5'>
                <h2 className='mb-3'>Colorimetría</h2>
                <p>¿Qué es la Colorimetría? Es el estudio basado en la Teoría del Color según las características
                    físicas de las personas. El color aplicado a las personas según sus ojos, cabello y piel. Según esto
                    las personas se pueden dividir en 4 Estaciones. Escoge el tuyo:</p>
            </div>

            <div className='d-flex flex-wrap justify-content-center '>
                {colorimetrias.map((colorimetria, key) => {
                    return (
                        <div
                            key={key}
                            className={`btn-square-calipso ${normalizeString(colorimetria.label) === searchState.categoriesValue[searchState.currentIndex] ? 'active' : ''} `}
                            onClick={() => dispatch(setSelected({value: colorimetria.label, currentIndex: 2}))}
                        >
                            <span>{colorimetria.label}</span>
                        </div>
                    )
                })}
            </div>


        </div>
    );
}

export default ProductsForm;
import React from 'react';
import {NavLink} from "react-router-dom";
import logo from '../../assets/svg/secondary-logo.svg'
import insta from '../../assets/img/insta.png'

function Footer() {
    return (
        <div className='footer allegory-container '>

            <div className='d-flex icons'>
                <p className='cp'>in</p>
                <p className='ms-2 cp'>f</p>
                <img className='ms-2 my-auto cp' alt=""  src={insta} style={{height: 15}}/>
            </div>

            <NavLink to='/'>Termino y condiciones</NavLink>

            <a href='https://docs.google.com/forms/d/e/1FAIpQLSe3Gk3yvOtl9loL_WiKQyFzF5SmtuOY21Q_OT4IRZ0IhSgZuA/viewform'>Dejanos
                tu feedback</a>

            <img src={logo} alt="" className='logo'/>


        </div>
    );
}

export default Footer;
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../redux/slices/SearchSlice";
import {RootState} from "../../redux";
import stages from '../../assets/stage.json'
import {normalizeString} from "../../utils";

function SituationForm() {

    const dispatch = useDispatch();
    const searchState = useSelector((state: RootState) => state.search);

    return (
        <div className='search-container '>
            <div className='mt-5 mb-5'>
                <h2 className='mb-3'>Etapa</h2>
                <p>Nuestra etapa de la vida por supuesto que influye en la manera de vestirnos. Es totalmente diferente
                    como nos vestimos a los 15, a los 30 o a los 50. También nos determina la etapa de la vida y nuestra
                    actividad, si estamos estudiando, trabajando, si nuestro trabajo es más bien formal o más relajado;
                    o si somos madres con niños pequeños o no. Por lo que también en este embudo puede ir:</p>
            </div>

            <div className='d-flex flex-wrap justify-content-center '>
                {stages.map((stage, key) => {
                    return (
                        <div
                            key={key}
                            className={`btn-square-calipso text-center ${normalizeString(stage.label) === searchState.categoriesValue[searchState.currentIndex] ? 'active' : ''} `}
                            onClick={() => dispatch(setSelected({value: stage.label, currentIndex: 5}))}
                        >
                            <span>{stage.label}</span>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}

export default SituationForm;
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../redux/slices/SearchSlice";
import products from '../../assets/products.json'
import {RootState} from "../../redux";
import {normalizeString} from "../../utils";

function ProductsForm() {

    const dispatch = useDispatch();
    const searchState = useSelector((state: RootState) => state.search);

    return (
        <div className='search-container '>
            <div className='mt-5 mb-5'>
                <h2 className='mb-3'>Productos</h2>
                <p>Tenemos un amplio catálogo de productos. Por favor escoge uno del listado a continuación: </p>
            </div>

            <div className='d-flex flex-wrap justify-content-center '>

                {products.map((product, key) => (
                    <div key={key} className='product-card' onClick={() => dispatch(setSelected({
                        value: product.label,
                        currentIndex: 0,
                    }))}>
                        <div
                            className={`img ${normalizeString(product.label) === searchState.categoriesValue[searchState.currentIndex] ? 'active' : ''}`}>
                            <div className='img-container'>
                                <img src={require(`../../assets/svg/icons/${product.src}`)} alt=""/>
                            </div>
                        </div>
                        <p>{product.label}</p>
                    </div>
                ))}

            </div>


        </div>
    );
}

export default ProductsForm;
import React from 'react';
import {Link} from "react-router-dom";

function Home() {
    return (
        <main className='d-flex'>
            <div className='allegory-main-image d-flex'>
                <div className='d-flex allegory-container my-auto main-padding-y '>
                    <div className='col-12 col-lg-6 col-xxl-4'>
                        <h1 className='allegory-h1'>Allegory es el primer
                            buscador personalizado de
                            moda.</h1>
                        <p className='my-4'>
                            Allegory es un buscador en línea inteligente desarrollado para facilitarte el proceso de
                            búsqueda de una prenda de vestir, según tus gustos personales, características físicas,
                            actividades que realizas, precios, colores y/o combinaciones para la que estás en busca de
                            aquella prenda; entre otros.
                        </p>

                        <div className='d-flex'>
                            <Link to='/buscador' className='btn-rounded btn-dark-c'>
                                <div className='d-flex align-items-center'>
                                    <p> Ir al buscador</p>
                                    <span className="material-icons-round ">arrow_forward</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );
}

export default Home;
import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux";
import Home from "./pages/Home";
import Navbar from "./components/navigators/Navbar";
import Footer from "./components/navigators/Footer";
import Us from "./pages/Us";
import Buscador from "./pages/Buscador";
import Results from "./pages/Results";
import Blog from "./pages/Blog";



function App() {
    return (
       <Provider store={store}>
           <BrowserRouter>
               <Navbar/>

               <Routes>
                   <Route path='/' element={<Home/>}/>
                   <Route path='/buscador' element={<Buscador/>}/>
                   <Route path='/resultados' element={<Results/>}/>
                   <Route path='/nosotras' element={<Us/>}/>
                   <Route path='/blog' element={<Blog/>}/>
               </Routes>

               <Footer/>
           </BrowserRouter>
       </Provider>
    );
}

export default App;

import React from 'react';

function Us() {
    return (
        <main className='bg-white'>

            <div className='container mt-5'>
                <div className='text-center my-4'>
                    <h2 className='allegory-h2'>Quiénes Somos</h2>
                </div>

                <p className='p-3 my-4'>
                    ALLEGORY es un buscador en línea inteligente. Estamos para facilitarte el proceso de búsqueda de una
                    prenda de vestir, según tus gustos personales, características físicas, actividades que realizas,
                    precios, colores y/o combinaciones para la que estás en busca de aquella prenda; entre otros.
                    Inicialmente estamos enfocados en moda mujer.
                </p>

                <p className='p-3 my-4'>
                    NO SOMOS UN MARKETPLACE, lo que hacemos es que con la información que quieras incorporar a la
                    búsqueda, podemos ofrecerte alternativas; ya sea, de e-commerce o retail o de aquellos pequeños que
                    ni siquiera sabías que existían; pues los buscadores actuales posicionan siempre aquellas marcas que
                    más gastan en publicidad o aquellas que ya tienen un posicionamiento en los buscadores.
                </p>

                <p className='p-3 my-4'>
                    CÓMO FUNCIONAMOS, a través de factores de búsqueda o filtros que tú misma vas proporcionando o
                    complementando. La información que completas es absoluta y totalmente confidencial, siendo utilizada
                    única y exclusivamente para fines de la búsqueda de aquella prenda de vestir.
                </p>


                <p className='p-3 my-4'>
                    QUÉ VALOR AGREGAMOS, evitar la pérdida de tiempo de que tengas que ir tienda por tienda mirando,
                    seleccionando, eligiendo o comparando. Somos una plataforma que te facilita la búsqueda de aquella
                    prenda específica que necesitas.
                </p>

                <p className='p-3 my-4'>
                    SI ENCUENTRAS LO QUE BUSCAS, maravilloso, te redireccionaremos al comercio en donde puedas
                    materializar la compra de dicha prenda, pero no tenemos ninguna responsabilidad, ni intervención
                    sobre dicha compra, lo nuestro es sólo guía, acompañamiento, sugerencias, asesoría e información.
                </p>

                <p className='p-3 my-4'>
                    FUTURO, tenemos grandes planes, esperamos con el tiempo sumar más y más comercios pequeños, grandes,
                    medianos, para ofrecerte mayores y mejores alternativas de búsqueda. Y esperamos también contar con
                    asesores que te orienten en la búsqueda de tu prenda ideal.
                </p>

                <p className='p-3 my-4'>
                    SI TE GUSTA, síguenos, comparte, comenta e iremos creciendo a través de la formación de una
                    comunidad inteligente en torno a la moda, que sea más sustentable y factible de incorporar a grandes
                    y pequeños.
                </p>

            </div>
        </main>
    );
}

export default Us;
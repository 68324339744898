import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../redux/slices/SearchSlice";
import {RootState} from "../../redux";
import shapes from '../../assets/shapes.json'
import {normalizeString} from "../../utils";

function BodyShapeForm() {

    const dispatch = useDispatch();
    const searchState = useSelector((state: RootState) => state.search);

    return (
        <div className='search-container '>
            <div className='mt-5 mb-5'>
                <h2 className='mb-3'>Tipo de Cuerpo</h2>
                <p>Todos los cuerpos son diferentes y es importante recalcar que no existen cuerpos perfectos, ni
                    mejores ni peores. La idea de identificar la forma de un cuerpo es para sacar lo mejor de cada uno,
                    identificando lo bueno para resaltarlo y contrastar lo que no está tan bien para lograr mayor
                    armonía entre todo. Selecciona una opción:</p>
            </div>

            <div className='d-flex flex-wrap justify-content-center '>
                {
                    shapes.map((shape,key)=>(
                        <div key={key}
                             onClick={() => dispatch(setSelected({value: shape.label, currentIndex: 3}))}
                             className='d-flex flex-column'>
                            <div className={`shape-img-container ${normalizeString(shape.label) === searchState.categoriesValue[searchState.currentIndex]? 'active':'' }`}>
                                <img src={require(`../../assets/img/body-shape/${shape.src}`)} alt=""/>
                            </div>
                            <p className='mx-auto w700'>{shape.label}</p>
                        </div>
                    ))
                }

            </div>


        </div>
    );
}

export default BodyShapeForm;
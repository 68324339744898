import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {normalizeString} from "../../utils";


interface SearchState {
    categories: Array<string>,
    currentIndex: number,
    categoriesValue: Array<any>
    // productSelected: string,
    // styleSelected: string,
    // colorimetrySelected: string,
    // typeBodySelected: string,
    // situationSelected: string,

    value: string
}

// int

const initialState: SearchState = {
    categories: ["Productos", "Estilo", "Colorimetría", "Tipo de cuerpo", "Ocasión", "Etapa"],
    categoriesValue: [null, null, null, null, null, null],
    currentIndex: 0
} as SearchState;

const SearchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchState: (state, {payload}: PayloadAction<Pick<SearchState, 'currentIndex'>>) => {
            const index = payload.currentIndex + 1 < state.categories.length ? payload.currentIndex + 1 : state.categories.length - 1;
            return {
                ...state,
                currentIndex: index,
                // [state.categories[payload.currentIndex]]:'A'+index,
            }
        },
        setSelected: (state, {payload}: PayloadAction<Pick<SearchState, 'currentIndex' | 'value'>>) => {
            let values = [...state.categoriesValue];
            values[payload.currentIndex] =normalizeString(payload.value)
            return {
                ...state,
                categoriesValue: values
            }
        }
    },
    extraReducers: undefined

});

export const {setSearchState, setSelected} = SearchSlice.actions;
export default SearchSlice.reducer;